import React from 'react'
// import logo from '../images/logo.png'
// import footerLogo from '../images/footerLogo.svg'
// import footerLogoPng from '../images/logo.png'
import footerLogoPng from '../images/logo.jpg'
import { SocialMediaIcons } from './Data'
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t } = useTranslation();

    return (
        <>
            {/* code to change the language */}
            {/* <button className="flex justify-between border-[1.5px] w-full py-3 px-2 rounded-lg my-1" onClick={() => i18next.changeLanguage('en')}>
                <div className="flex justify-center gap-2 mx-2">
                    <img src={docIcon} alt="some" />
                    <h1>English</h1>
                </div>
                <h1 className="font-bold text-mainBrown">{`>`}</h1>
            </button> */}

            {/* Mobile */}
            <div className='md:hidden '>

                <div className='rounded-t-[20px] w-full bg-[#4E2D1E] mt-20 text-white flex flex-col'>
                    {/* <div className='flex ms-3 mt-3 justify-start items-center'>
                        <img className='h-[100px] w-[100px]' src={footerLogo} alt='footerLogo' />
                        <h1 className='text-[24px] text-center font-bold leading-tight'>{t('shahad_promise')}</h1>
                    </div> */}
                    <div className='grid grid-cols-3 ms-3 mt-3 justify-start items-center'>
                        <div className='flex justify-center h-[100px] w-[100px] rounded-[10px]  bg-white'>
                            <img className='h-full w-full rounded-[10px]' src={footerLogoPng} alt='footerLogo' />
                        </div>
                        <h1 className='text-[24px] col-span-2  text-center font-bold leading-tight'>{t('shahad_promise')}</h1>
                    </div>

                    <div className='flex justify-center gap-6 my-8'>
                        {SocialMediaIcons.map((item, index) => (
                            <a key={index} href={item.link}>
                                <img className='h-[30px] w-[30px]' src={item.icon} alt={item.icon} />
                            </a>
                        ))}
                    </div>

                    <hr className='mb-3' />

                    <div className='flex justify-center mb-8'>
                        <h1>{t('Copywrite')}</h1>
                    </div>
                </div>
            </div>
            {/* Desktop */}
            <div className='hidden md:block'>Desktop</div>
        </>
    )
}

export default Footer